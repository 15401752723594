import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Dispatch } from 'redux';

import * as Yup from 'yup';
import {
	Formik, Form, FieldProps,
} from 'formik';

import { member } from '@common/react/utils/decorators';
import Button from '@common/react/components/Forms/Button';
import { Loading } from '@common/react/components/UI/Loading/Loading';
import { emailValidator, simpleStringValidator } from '@common/react/utils/validationHelpers';

import { ApplicationState } from '@app/store';
import {
	bindLoginActionCreators,
	KnownLoginActions,
	LoginActionCreators,
	LoginState,
} from '@app/store/Login';
import { PasswordField } from '@app/components/UI/PasswordField/PasswordField';
import { FormikField } from '@app/components/Forms/FormikField';

interface ReduxProps {
	login: LoginState;
}

interface ReduxActions {
	actions: LoginActionCreators;
}

type LoginProps = ReduxProps & ReduxActions & RouteComponentProps;

interface FormValues {
	login: string;
	password: string;
}

const validationSchema = Yup.object().shape({
	login: emailValidator,
	password: simpleStringValidator,
});

class LoginInner extends React.Component<LoginProps> {
	public componentDidMount() {
		if (this.props.login.user) {
			this.redirect();
		}
	}

	public componentDidUpdate(prevProps: Readonly<LoginProps>) {
		if (prevProps.login.user === null && this.props.login.user !== null) {
			this.redirect();
		}
	}

	@member
	protected handleSubmit(values: FormValues): void {
		this.props.actions.login(values.login, values.password);
	}

	private loginPasswordForm(): React.ReactNode {
		return (
			<>
				<FormikField
					fieldName="login"
					inputProps={{
						placeholder: 'Email',
					}}
				/>
				<FormikField
					fieldName="password"
					render={({ field }: FieldProps<string, FormValues>) => (<PasswordField {...field} placeholder="Password" />)}
				/>
			</>
		);
	}

	private serverMessageRender(): React.ReactNode {
		if (this.props.login.message) {
			return <div className="alert alert-danger">{this.props.login.message}</div>;
		}

		return null;
	}

	@member
	private redirect(): void {
		this.props.history.replace('/dashboard');
	}

	public render(): React.ReactNode {
		// This is here for ssr only
		if (this.props.login.user) {
			this.redirect();

			return <Loading />;
		}

		return (
			<div className="enter-page">
				<Helmet>
					<title>Login</title>
				</Helmet>
				<Link to="/"><img src={require('@images/logo.png')} alt="Logo" className="enter-page__logo" /></Link>
				<div className="enter-page__form card">
					<div className="card__body">
						<Formik
							initialValues={{ login: '', password: '' }}
							onSubmit={this.handleSubmit}
							validationSchema={validationSchema}
						>
							{() => (
								<Form>
									{this.loginPasswordForm()}
									<div className="text-center">
										<Button
											className="btn btn-primary btn-block"
											isLoading={this.props.login.isLoading}
										>
											Login
										</Button>
									</div>
									<div className="row enter-page__links">
										<div className="col-xs-7">
											<Link to="/recover" className="enter-page__link">
												<i className="fa fa-lock" aria-hidden="true" />
												Forgot password?
											</Link>
										</div>
										<div className="col-xs-5 text-right">
											<Link to="/registration" className="enter-page__link">
												<i
													className="fa fa-user-circle-o"
													aria-hidden="true"
													style={{ marginRight: '8px' }}
												/>
												Register
											</Link>
										</div>
										<div className="col-sm-12">
											<a
												href="https://www.wingstrackersoftware.com"
												className="enter-page__link"
												target="_blank"
												rel="noreferrer"
											>
												<i className="fa fa-info" aria-hidden="true" />
												Learn more about Wingstracker Portal
											</a>
										</div>
									</div>
								</Form>
							)}
						</Formik>
						{this.serverMessageRender()}
					</div>
				</div>
			</div>
		);
	}
}

export const Login = connect<ReduxProps, ReduxActions, RouteComponentProps, ApplicationState>(
	(state: ApplicationState) => ({ login: { ...state.login } }),
	(dispatch: Dispatch<KnownLoginActions>) => ({
		actions: bindLoginActionCreators(dispatch),
	}),
)(LoginInner);
